import { useNuxtApp } from '#app';

export const useBadRoutes = () => {
  const route = useRoute();
  const backend = useBackend();
  const nuxtApp = useNuxtApp();
  const router = useRouter();

  const routeToBrand = async () => {
    // model not found, so check if brand exists
    const brands = await backend.getBrands();
    const brandExists = brands.data.find(i => i.name.toLowerDashed() === route.params.brand.toLowerDashed())
    if(brandExists) {
      await nuxtApp.runWithContext(
        async () => {
          await navigateTo({name: 'brand', params: {brand: route.params.brand}}, {replace: true})
          throw createError({
            statusMessage: "Moved permanently",
            statusCode: 301,
          })
        }
      )
      return;
    }
    await redirectToRoot();
  }

  const routeToOldOffer = async () => {
    await nuxtApp.runWithContext(
      async () => {
        await navigateTo(`/o/${route.params.uuid}`, { redirectCode: 301, replace: true });
        throw createError({
          statusMessage: "Moved permanently",
          statusCode: 301,
        });
      }
    );
  };

  const redirectToRoot = async () => {
    return nuxtApp.runWithContext(
      async () => {
        await navigateTo("/",{redirectCode: 301, replace: true})
        throw createError({
          statusMessage: "Moved permanently",
          statusCode: 301,
        })
      }
    )
  }
  const handle = async () => {
    return await (({
      "model": routeToBrand,
      "cars": routeToBrand,
      "external-offer": routeToOldOffer
    })[route.name] || redirectToRoot)()
  }

  return {
    handle
  }

}
